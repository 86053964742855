import React, { useState, useEffect } from 'react';
import Header from '../Header'; 
import { useNavigate  } from 'react-router-dom';

function TermsofUse() {
  const navigate = useNavigate();

  const privacyLink = () => {
      navigate('/privacy/');
  };

  const termsLink = () => {
    navigate('/terms/');
};

  return (
    <div className="App">
      <Header />
      <header
        className="w-full bg-cover bg-center"
        style={{
          height: "24rem",
          backgroundImage: "url(/)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top"
        }}
      >
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-70">
          <div className="text-center">
            <h1 className="text-4xl text-white font-bold leading-tight">
              Terms of Use
            </h1>
          </div>
        </div>
      </header>
      <main className="container mx-auto py-8 px-4 lg:px-0">
        <article className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-8">
        <p className="mb-4">
          These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Stack Techy (“we,” “us” or “our”), concerning your access to and use of the stacktechy.com website as well as any other media form, media channel, mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).
        </p>

        <h2 className="text-xl font-semibold mb-2">User Representations</h2>
        <p className="mb-4">
          By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of Use; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; (6) you will not use the Site for any illegal or unauthorized purpose; and (7) your use of the Site will not violate any applicable law or regulation.
        </p>

        <h2 className="text-xl font-semibold mb-2">Prohibited Activities</h2>
        <p className="mb-4">
          You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Systematically retrieve data or other content from the Site</li>
          <li>Trick, defraud, or mislead us and other users</li>
          <li>Engage in unauthorized framing of or linking to the Site</li>
          <li>Use any information obtained from the Site to harass, abuse, or harm another person</li>
          <li>Make improper use of our support services or submit false reports of abuse or misconduct</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">User Generated Contributions</h2>
        <p className="mb-4">
          The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site.
        </p>

        <h2 className="text-xl font-semibold mb-2">Contribution License</h2>
        <p className="mb-4">
          By posting your Contributions to any part of the Site, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions.
        </p>

        <h2 className="text-xl font-semibold mb-2">Site Management</h2>
        <p className="mb-4">
          We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.
        </p>

        <h2 className="text-xl font-semibold mb-2">Modifications and Interruptions</h2>
        <p className="mb-4">
          We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
        </p>

        <h2 className="text-xl font-semibold mb-2">Governing Law</h2>
        <p className="mb-4">
          These Terms shall be governed by and defined following the laws. Stack Techy and yourself irrevocably consent that the courts shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
        </p>

        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="mb-4">
          In order to resolve a complaint regarding the Site or to receive further information regarding the use of the Site, please contact us at:
        </p>
        <p className="mb-4">
          <strong>Stack Techy</strong><br />
          support@stacktechy.com<br />
        </p>
          </div>
        </article>

      </main>
      <footer className="bg-gray-900 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="mt-2 text-white-600 leading-relaxed">
            Disclaimer
            <br />
            We strive to provide accurate and timely information, but cannot guarantee
            that all content on this site is correct or up-to-date. Our articles and
            reports are intended for informational purposes only, and we do not
            endorse any specific products or investments. Readers are advised to
            verify the facts and conduct their own research before making any
            decisions based on the information provided here.
          </p><br/>
          <p><span onClick={privacyLink} style={{cursor: "pointer"}}>Privacy Policy</span> | <span onClick={termsLink} style={{cursor: "pointer"}}>Terms of Use</span></p>
          <p className="mt-5">© 2024 Stack Techy. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default TermsofUse;
