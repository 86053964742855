import React, { useState, useEffect } from 'react';
import Header from '../Header'; 
import { useNavigate  } from 'react-router-dom';

function PrivacyPolicy() {
  const navigate = useNavigate();

  const privacyLink = () => {
      navigate('/privacy/');
  };

  const termsLink = () => {
    navigate('/terms/');
};
  return (
    <div className="App">
      <Header />
      <header
        className="w-full bg-cover bg-center"
        style={{
          height: "24rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top"
        }}
      >
        <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-70">
          <div className="text-center">
            <h1 className="text-4xl text-white font-bold leading-tight">
              Privacy Policy
            </h1>
          </div>
        </div>
      </header>
      <main className="container mx-auto py-8 px-4 lg:px-0">
        <article className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-8">
        <p className="mb-4">
          Welcome to Stack Techy. This Privacy Policy explains how we
          collect, use, disclose, and safeguard your information when you visit
          our website stacktechy.com.
        </p>

        <h2 className="text-xl font-semibold mb-2">Information We Collect</h2>
        <p className="mb-4">
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Personal Data</li>
          <li>Derivative Data</li>
          <li>Financial Data</li>
          <li>Mobile Device Data</li>
          <li>Third-Party Data</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">Use of Your Information</h2>
        <p className="mb-4">
          Having accurate information about you permits us to provide you with
          a smooth, efficient, and customized experience. Specifically, we may
          use information collected about you via the Site to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Create and manage your account</li>
          <li>Process your transactions</li>
          <li>Email you regarding your account or order</li>
          <li>Enable user-to-user communications</li>
          <li>Generate a personal profile about you</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">Disclosure of Your Information</h2>
        <p className="mb-4">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>By Law or to Protect Rights</li>
          <li>Third-Party Service Providers</li>
          <li>Marketing Communications</li>
          <li>Business Transfers</li>
          <li>Affiliates</li>
        </ul>

        <h2 className="text-xl font-semibold mb-2">Security of Your Information</h2>
        <p className="mb-4">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>

        <h2 className="text-xl font-semibold mb-2">Policy for Children</h2>
        <p className="mb-4">
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible. If
          you believe we might have any information from or about a child under
          13, please contact us at privacy@stacktechy.com.
        </p>

        <h2 className="text-xl font-semibold mb-2">Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p className="mb-4">
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>
        <p className="mb-4">
          <strong>Stack Techy</strong><br />
          privacy@stacktechy.com<br />
        </p>
          </div>
        </article>

      </main>
      <footer className="bg-gray-900 text-white py-6">
        <div className="container mx-auto text-center">
          <p className="mt-2 text-white-600 leading-relaxed">
            Disclaimer
            <br />
            We strive to provide accurate and timely information, but cannot guarantee
            that all content on this site is correct or up-to-date. Our articles and
            reports are intended for informational purposes only, and we do not
            endorse any specific products or investments. Readers are advised to
            verify the facts and conduct their own research before making any
            decisions based on the information provided here.
          </p><br/>
          <p><span onClick={privacyLink} style={{cursor: "pointer"}}>Privacy Policy</span> | <span onClick={termsLink} style={{cursor: "pointer"}}>Terms of Use</span></p>
          <p className="mt-5">© 2024 Stack Techy. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
